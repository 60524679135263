//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base';
@import 'layout/layout';

/* Setup .modal-open body overflow */

body.modal-open {
  overflow: hidden;
}
