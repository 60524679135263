.react-select-dark-container {
  .react-select-dark__control {
    background-color: var(--kt-input-bg);
    border-color: var(--kt-input-border-color);
    transition: none;

    &:hover {
      border-color: var(--kt-input-border-color);
    }
  }

  .react-select-dark__menu {
    background-color: var(--kt-input-bg);
    border: 1px solid var(--kt-input-border-color);
  }

  .react-select-dark__option {
    color: var(--kt-input-color) !important;
    background-color: var(--kt-input-bg);

    &:hover {
      background-color: #5b5c69; /* var(--bg-primary); */
    }
  }

  .react-select-dark__indicator-separator {
    background-color: var(--kt-input-border-color);
  }

  .react-select-dark__placeholder {
    color: kt-input-placeholder-color !important;
  }

  .react-select-dark__single-value,
  .react-select-dark__input {
    color: var(--kt-input-color) !important;
  }
}

/* This is technically for the time picker: */

.react-samay-panel-combobox {
  background-color: var(--kt-input-bg) !important;
  border-color: var(--kt-input-border-color) !important;
  border-left: 1px solid var(--kt-input-border-color) !important;
  border-right: 1px solid var(--kt-input-border-color) !important;

  .react-samay-panel-select {
    border: 1px solid var(--kt-input-border-color) !important;
  }

  .react-samay-panel-select-option-selected {
    background-color: var(--kt-secondary) !important;
  }

  li:hover {
    background-color: var(--kt-secondary) !important;
  }
}
